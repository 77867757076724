import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { icon } from 'leaflet';

// Fix for default marker icon
const defaultIcon = icon({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

export function Map() {
  // Bell Post Hill coordinates (10 Beauford Ave)
  const position: [number, number] = [-38.104206346447555, 144.3275170001307];

  return (
    <section className="py-12 md:py-20 px-4 bg-gray-50" id="location">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-6 md:mb-8 font-display text-gray-800">Find Us</h2>
        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
          <div className="w-full h-[300px] md:h-[450px] relative">
            <MapContainer
              center={position}
              zoom={17}
              scrollWheelZoom={false}
              style={{ height: '100%', width: '100%' }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={position} icon={defaultIcon}>
                <Popup>
                  Silver's Fish & Chips<br />
                  10 Beauford Ave, Bell Post Hill
                </Popup>
              </Marker>
            </MapContainer>
          </div>
          <div className="p-4 md:p-6 bg-white">
            <div className="flex items-center gap-3 text-gray-700">
              <div>
                <h3 className="font-display font-semibold mb-2">Location</h3>
                <p className="font-medium text-sm md:text-base">10 Beauford Ave, Bell Post Hill VIC 3215</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}