import { Star, Phone, Menu } from 'lucide-react';

const reviews = [
  {
    text: "This is the go to place for classic fish and chips. Extensive selection, great customer service, great value and they always deliver on taste. Highly recommend!",
    author: "Mary B.",
    rating: 5
  },
  {
    text: "Well worth it. The fish is always huge and the chips are perfect. Best potato cakes. We are happy to call Silvers our local. Thanks Silvers",
    author: "Stevie Q.",
    rating: 5
  },
  {
    text: "Best fish and chips in Geelong! Quality ingredients, generous servings and quick & friendly service. I'll be back!",
    author: "Simon C.",
    rating: 5
  }
];

export function Hero() {
  return (
    <div className="relative min-h-screen">
      <div className="absolute inset-0">
        <img
          src="https://images.unsplash.com/photo-1530053969600-caed2596d242?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3"
          alt="Ocean waves background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-br from-blue-900/90 via-blue-700/85 to-blue-500/80 animate-gradient"></div>
      </div>
      
      <div className="relative min-h-screen flex flex-col items-center justify-between py-8 md:py-12 px-4">
        <div className="absolute top-0 left-0 w-full h-32 bg-gradient-to-b from-black/50 to-transparent"></div>
        
        <div /> {/* Spacer */}
        
        <div className="space-y-6 md:space-y-8 text-center my-auto py-8">
          <div>
            <img 
              src="https://i.imgur.com/JW3Eq1V.png"
              alt="Silver's Fish & Chips Logo"
              className="w-28 h-28 md:w-36 md:h-36 mx-auto"
            />
          </div>
          
          <div className="space-y-3 md:space-y-4">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-title mb-2 md:mb-4 text-center tracking-wide drop-shadow-lg text-white">
              Silver's Fish & Chips
            </h1>
            <p className="text-lg sm:text-xl md:text-lg text-center max-w-2xl font-body text-white/90">
              Serving Geelong's finest fish & chips since 2018
            </p>
          </div>
          
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <a
              href="#menu"
              className="bg-white text-blue-600 px-4 py-3 rounded-full font-semibold text-sm 
                       hover:bg-blue-50 transition-colors duration-300 shadow-lg
                       flex items-center justify-center gap-2 w-full sm:w-[180px]"
            >
              <Menu className="w-5 h-5" />
              View Menu
            </a>
            <a
              href="tel:0352787794"
              className="bg-secondary text-white px-4 py-3 rounded-full font-semibold text-sm 
                       hover:bg-opacity-90 transition-colors duration-300 shadow-lg
                       flex items-center justify-center gap-2 w-full sm:w-[180px]"
            >
              <Phone className="w-5 h-5" />
              Call Us Now
            </a>
          </div>
        </div>

        <div className="mt-0 md:mt-1 flex flex-col sm:flex-row justify-center gap-3 max-w-4xl">
          {reviews.map((review, index) => (
            <div 
              key={index}
              className="bg-white/10 backdrop-blur-sm p-2 md:p-3 rounded-lg max-w-[240px] md:max-w-xs flex-1 min-w-[220px]"
            >
              <div className="flex justify-center gap-1 mb-1">
                {[...Array(review.rating)].map((_, i) => (
                  <Star key={i} className="w-3.5 h-3.5 text-yellow-400 fill-current" />
                ))}
              </div>
              <p style={{ fontSize: '0.7rem' }} className="mb-1 text-white text-center">
                {review.text}
              </p>
              <p className="text-xs text-white/80 text-center">{review.author}</p>
            </div>
          ))}
        </div>
        
        <div className="absolute bottom-0 left-0 w-full h-32 bg-gradient-to-t from-black/50 to-transparent"></div>
      </div>
    </div>
  );
}