import { Clock, MapPin, Phone } from 'lucide-react';
import { hoursData } from '../data/hoursData';

export function Footer() {
  return (
    <footer className="bg-gradient-to-br from-blue-900 via-blue-800 to-blue-700 text-white py-12 font-body">
      <div className="max-w-6xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12">
          <div className="flex items-start space-x-3">
            <Clock className="w-6 h-6 mt-1 text-secondary flex-shrink-0" />
            <div>
              <h3 className="font-display font-semibold mb-2 text-lg">Opening Hours</h3>
              <div className="text-gray-300 space-y-1 text-sm md:text-base">
                {hoursData.map((hours, index) => (
                  <p key={index}>{hours}</p>
                ))}
              </div>
            </div>
          </div>
          
          <div className="flex items-start space-x-3">
            <MapPin className="w-6 h-6 mt-1 text-secondary flex-shrink-0" />
            <div>
              <h3 className="font-display font-semibold mb-2 text-lg">Location</h3>
              <p className="text-gray-300 text-sm md:text-base">10 Beauford Ave, Bell Post Hill VIC 3215</p>
            </div>
          </div>
          
          <div className="flex items-start space-x-3">
            <Phone className="w-6 h-6 mt-1 text-secondary flex-shrink-0" />
            <div>
              <h3 className="font-display font-semibold mb-2 text-lg">Contact</h3>
              <p className="text-gray-300 text-sm md:text-base">03 5278 7794</p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="mt-8 text-center">
        <p className="text-gray-300 text-xs md:text-sm">© 2024 Silver's Fish & Chips. All rights reserved.</p>
      </div>
    </footer>
  );
}