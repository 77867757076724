import React from 'react';
import { MenuItem } from '../types';

interface MenuSectionProps {
  title: string;
  items: MenuItem[];
}

export function MenuSection({ title, items }: MenuSectionProps) {
  return (
    <div>
      <h3 className="text-xl md:text-2xl font-semibold mb-4 md:mb-6 text-gray-800 font-display">{title}</h3>
      <div className="grid gap-4 md:gap-6">
        {items.map((item) => (
          <div
            key={item.name}
            className="flex flex-col sm:flex-row sm:justify-between sm:items-baseline border-b border-gray-200 pb-4 group hover:border-secondary transition-colors"
          >
            <div className="mb-1 sm:mb-0">
              <h4 className="text-base md:text-lg font-medium text-gray-900 group-hover:text-secondary transition-colors">
                {item.name}
              </h4>
              {item.description && (
                <p className="text-sm text-gray-600 group-hover:text-secondary mt-1 font-body pr-4 transition-colors">{item.description}</p>
              )}
            </div>
            <span className="text-base md:text-lg font-medium text-gray-900 group-hover:text-secondary transition-colors font-display whitespace-nowrap">
              ${item.price.toFixed(2)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}