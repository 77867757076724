import { MenuItem, MenuData } from '../types';

class MenuParserError extends Error {
  constructor(message: string, line: number) {
    super(`Error on line ${line}: ${message}`);
  }
}

export function parseMenuFile(content: string): MenuData {
  const menu: MenuData = {};
  let currentCategory = '';
  let lineNumber = 0;

  // Validate entire file structure
  if (!content.trim()) {
    throw new MenuParserError('Menu file is empty', 0);
  }

  content.split('\n').forEach(line => {
    lineNumber++;
    line = line.trim();
    
    // Skip empty lines
    if (!line) return;

    // Handle category lines
    if (line.startsWith('//')) {
      currentCategory = line.slice(2).trim();
      if (!currentCategory) {
        throw new MenuParserError('Category name cannot be empty', lineNumber);
      }
      menu[currentCategory] = [];
      return;
    }

    // Handle menu items
    if (!currentCategory) {
      throw new MenuParserError('Menu item must be under a category', lineNumber);
    }

    const parts = line.split('|').map(s => s.trim());
    
    // Validate item format
    if (parts.length !== 3) {
      throw new MenuParserError(
        'Invalid menu item format. Expected: Name | Description | Price',
        lineNumber
      );
    }

    const [name, description, priceStr] = parts;
    
    // Validate required fields
    if (!name) {
      throw new MenuParserError('Item name is required', lineNumber);
    }

    const price = parseFloat(priceStr);
    if (isNaN(price) || price < 0) {
      throw new MenuParserError(
        'Price must be a valid positive number',
        lineNumber
      );
    }

    // Add validated item to menu
    menu[currentCategory].push({
      name,
      description: description || undefined,
      price,
    });
  });

  // Validate at least one category exists
  if (Object.keys(menu).length === 0) {
    throw new MenuParserError('Menu must contain at least one category', lineNumber);
  }

  return menu;
}