import { MenuSection } from './MenuSection';
import { menuData } from '../data/menuData';

export function Menu() {
  return (
    <section id="menu" className="py-12 md:py-20 px-4 bg-white">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 md:mb-16 font-display text-gray-800">Our Menu</h2>
        <div className="grid gap-8 md:gap-16">
          {Object.entries(menuData).map(([category, items]) => (
            <MenuSection key={category} title={category} items={items} />
          ))}
        </div>
      </div>
    </section>
  );
}