import { Hero } from './components/Hero';
import { Menu } from './components/Menu';
import { Map } from './components/Map';
import { Footer } from './components/Footer';

export default function App() {
  return (
    <div className="min-h-screen font-body">
      <Hero />
      <Menu />
      <Map />
      <Footer />
    </div>
  );
}